import { useSelector } from 'react-redux';

export const usePermission = () => {
  const userPermissions = useSelector(
    (state) => state.auth.authUser?.permissions
  );
  
  const evaluate = (permissions = ['']) => {
      return Array.isArray(permissions) && permissions.every((permission) =>
        userPermissions?.includes(permission)
    );
  };

  return { evaluate };
};

export const permissionList = {
  acamReportCreate: 'acam-report:create',
  acamReportDelete: 'acam-report:delete',
  acamReportExport: 'acam-report:export',
  acamReportExportTransactions: 'acam-report:export-transactions',
  acamReportRead: 'acam-report:read',
  acamReportUpdate: 'acam-report:update',
  amountTransactionRead: 'amount-transaction:read',
  assignMerchantUpdate: 'assign merchant:update',
  auditCreate: 'audit:create',
  auditReact: 'audit:read',
  auto2FaRemove: 'auth2fa:remove',
  bankCreate: 'bank:create',
  bankDelete: 'bank:delete',
  bankExport: 'bank:export',
  bankPaymentMethodCreate: 'bank-payment-method:create',
  bankPaymentMethodDelete: 'bank-payment-method:delete',
  bankPaymentMethodUpdate: 'bank-payment-method:update',
  bankRead: 'bank:read',
  bankUpdate: 'bank:update',
  countTransactionsBanksRead: 'count-transactions-banks:read',
  currencyCreate: 'currency:create',
  currencyDelete: 'currency:delete',
  currencyRead: 'currency:read',
  currencyUpdate: 'currency:update',
  customerBankCreate: 'customer-bank:create',
  customerBankDelete: 'customer-bank:delete',
  customerBankRead: 'customer-bank:read',
  customerBankUpdate: 'customer-bank:update',
  customerDocumentCreate: 'customer-document:create',
  customerDocumentExport: 'customer-document:export',
  customerDocumentRead: 'customer-document:read',
  customerExport: 'customer:export',
  customerRead: 'customer:read',
  customerRemovePep: 'customer:remove-pep',
  customerUpdate: 'customer:update',
  documentApprove: 'document:approve',
  documentCreate: 'document:create',
  documentRefuse: 'document:refuse',
  global: 'global',
  healthRead: 'health:read',
  kycManualRequest: 'kyc:manual-request',
  kycUpdateStatus: 'kyc:update-status',
  loginAdmin: 'login:admin',
  manualTransaction: 'bank:manual-transaction',
  menuBalances: 'menu:balances',
  menuBanks: 'menu:banks',
  menuMerchants: 'menu:merchants',
  merchantCreate: 'merchant:create',
  merchantDelete: 'merchant:delete',
  merchantExport: 'merchant:export',
  merchantMonthlyReportsDownload: 'merchant-monthly-reports:download',
  merchantMonthlyReportsRead: 'merchant-monthly-reports:read',
  merchantRead: 'merchant:read',
  merchantSecretUpdate: 'merchant-secret:update',
  merchantUpdate: 'merchant:update',
  notesCreate: 'notes:create',
  notesDelete: 'notes:delete',
  notesRead: 'notes:read',
  notesUpdate: 'notes:update',
  notificationExport: 'notification:export',
  notificationRead: 'notification:read',
  oAuthClientRead: 'oauth-client:read',
  passwordUpdate: 'password:update',
  paymentMethodCreate: 'payment-method:create',
  paymentMethodDelete: 'payment-method:delete',
  paymentMethodUpdate: 'payment-method:update',
  pepCreate: 'pep:create',
  pepDelete: 'pep:delete',
  pepRead: 'pep:read',
  pepUpdate: 'pep:update',
  profileRead: 'profile:read',
  profitRead: 'profit:read',
  relatedPepApprove: 'related-pep:approve',
  relatedPepCreate: 'related-pep:create',
  relatedPepDelete: 'related-pep:delete',
  relatedPepRead: 'related-pep:read',
  relatedPepRemove: 'related-pep:remove',
  relatedPepUpdate: 'related-pep:update',
  simulateTransactionCreate: 'simulate-transaction:create',
  summaryDailyTransactionsExport: 'summary-daily-transactions:export',
  summaryDailyTransactionsRead: 'summary-daily-transactions:read',
  summaryPendingWithdrawal: 'summary-pending-withdrawals:read',
  summaryTransactionsCustomerExport: 'summary-transactions-customer:export',
  summaryTransactionsCustomerRead: 'summary-transactions-customer:read',
  superSetRead: 'superset:read',
  transactionApprove: 'transaction:approve',
  transactionCallbackFailedDelete: 'transactions-callback-failed:delete',
  transactionCallbackFailedRead: 'transactions-callback-failed:read',
  transactionCancelReasonRead: 'transactions-cancel-reason:red',
  transactionExport: 'transaction:export',
  transactionExportOffline: 'transaction:export-offline',
  transactionManualCancel: 'transaction:manual-cancel',
  transactionManualComplete: 'transaction:manual-complete',
  transactionManualRefund: 'transaction:manual-refund',
  transactionManualRetry: 'transaction:manual-retry',
  transactionPendingReviewRead: 'transaction-pending-review:read',
  transactionIssueRead: 'transaction-issue:read',
  transactionIssueUpdate: 'transaction-issue:update',
  transactionRead: 'transaction:read',
  transactionRefuse: 'transaction:refuse',
  transactionReviewExport: 'transaction-review:export',
  transactionReviewRead: 'transaction-review:read',
  transactionSummaryRead: 'transaction-summary:read',
  updateWallet: 'update:wallet',
  userCreate: 'user:create',
  userExport: 'user:export',
  userMerchantCreate: 'user-merchant:create',
  userMerchantExport: 'user-merchant:export',
  userMerchantRead: 'user-merchant:read',
  userMerchantUpdate: 'user-merchant:update',
  userRead: 'user:read',
  userUpdate: 'user:update',
  walletBankCreate: 'wallet-bank:create',
  walletBankDelete: 'wallet-bank:delete',
  walletBankRead: 'wallet-bank:read',
  walletBankUpdate: 'wallet-bank:update',
  walletCreate: 'wallet:create',
  walletDelete: 'wallet:delete',
  walletRead: 'wallet:read',
  walletUpdate: 'wallet:update',
};
